@import "../../assets/font/font.scss";

.window {
  position: fixed;
  border-radius: 10px;
  font-family: "Inconsolata";
  font-size: 15px;
  overflow: hidden;
  border: 2px solid blue;
  border-radius: 10px;

  &__header {
    position: relative;
    background-color: blue;
    height: 22px;
    font-weight: bold;
    font-size: 14px;
    color: white;
    text-align: center;
    line-height: 22px;
    user-select: none;
  }

  &__button {
    position: absolute;
    top: 5px;
    height: 11px;
    width: 11px;
    border-radius: 6px;
    transition: all 0.2s ease;

    &.-exit {
      left: 6px;
      background-color: rgb(253, 37, 22);

      &:hover {
        background-color: rgb(255, 138, 138);
      }
    }

    &.-minimize {
      left: 23px;
      background-color: rgb(255, 187, 0);

      &:hover {
        background-color: rgb(255, 237, 74);
      }
    }

    &.-maximize {
      left: 40px;
      background-color: rgb(21, 206, 67);

      &:hover {
        background-color: rgb(52, 245, 100);
      }
    }
  }

  &__content {
    max-width: 1200px;
    max-height: 640px;
    border-top: 0;
    overflow: hidden;

    &.-text,
    &.-explorer {
      background-color: white;
      overflow: scroll;
      overflow-x: hidden;
      
      user-select: auto;
    }

    .text, .explorer {
      line-height: 1.2;
      padding: 20px;
      hyphens: auto;
      user-select: auto;
    }

    .explorer {
      padding-top: 30px;
    }

    &.-picture {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
    }

    .picture,
    .video {
      max-width: 100%;
      max-height: 100%;
    }

    &.-video {
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .video {
      width: 800px;
      height: 500px;
    }

    &.-pdf {
      overflow: scroll;
      overflow-x: hidden;
      background-color: white;

      .downloadIcon {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        bottom: 10px;
        right: 25px;
        background-color: black;
        background-image: url(../../assets/sprites/download-icon.svg);
        opacity: 0.5;
        transition: all 0.2s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .pdf {
      min-width: 500px;
      max-width: 100%;
      width: 1200px;
    }

    &.-folder,
    &.-recycle,
    &.-computer {
      padding: 10px;
      background-color: white;
      overflow: scroll;
    }
  }

  &__scaleIcon {
    background-image: url("../../assets/sprites/scale-corner.svg");
    position: absolute;
    height: 14px;
    width: 14px;
    right: 4px;
    bottom: 4px;
    border: 0;
  }

  &__scaleArea {
    position: absolute;
    height: 18px;
    width: 18px;
    right: -4px;
    bottom: -4px;
    cursor: nwse-resize;
  }
}
