.spotify {
  position: relative;
  width: 100%;
  min-width: 280px;
  height: 100%;
  background-color: rgb(49, 49, 49);
  user-select: none;

  &__playlist {
    position: absolute;
    width: calc(65% - 20px);
    height: calc(100% - 80px);
    right: 0px;
    overflow-y: scroll;
    padding: 10px;
  }

  &__albumCover {
    position: absolute;
    max-width: 35%;
    bottom: 60px;
  }
}

.spotifyToolBar {
  position: absolute;
  width: 100%;
  min-width: 350px;
  height: 60px;
  bottom: 0px;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__info,
  &__control,
  &__volume {
    width: 30%;
    height: 80%;
  }

  &__info {
    color: white;
  }

  &__title,
  &__artist {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    margin: 5px 10px 5px 10px;
  }

  &__artist {
    font-size: 13px;
    margin: 0 0 0 10px;
  }

  &__button {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    left: 50%;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgb(77, 77, 77);
    }

    &.-play {
      transform: translate(-50%, 0);
      background-image: url("../../assets/sprites/spotify-play.svg");
    }

    &.-pause {
      transform: translate(-50%, 0);
      background-image: url("../../assets/sprites/spotify-pause.svg");
    }

    &.-next {
      transform: translate(calc(-50% + 30px), 0);
      background-image: url("../../assets/sprites/spotify-next.svg");
    }

    &.-prev {
      transform: translate(calc(-50% - 30px), 0);
      background-image: url("../../assets/sprites/spotify-prev.svg");
    }
  }

  &__songBar {
    position: absolute;
    bottom: 8px;
    width: 30%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__volume {
    position: relative;
  }

  &__volumeIcon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 16px;
    right: calc(40% + 28px);
    background-image: url("../../assets/sprites/spotify-volume.svg");

    &:hover {
      background-color: rgb(77, 77, 77);
    }

    &.-mute {
      background-image: url("../../assets/sprites/spotify-volume-mute.svg");
    }
  }

  &__volumeBar {
    position: absolute;
    width: 40%;
    top: 15px;
    right: 20px;
  }
}

.spotifyListItem {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid grey;

  &:first-child {
    border-top: 1px solid grey;
  }

  &:hover {
    background-color: rgb(77, 77, 77);
  }

  &__title,
  &__artist {
    color: white;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__title {
    margin: 0 10px;
    width: 50%;
  }

  &__artist {
    width: 30%;
  }

  &.-isPlaying {
    .spotifyListItem__title,
    .spotifyListItem__artist {
      color: rgb(0, 255, 0);
    }
  }
}
