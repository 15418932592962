.toolbar {
  position: absolute;
  width: 100%;
  top: 22px;
  left: 0;
  background-color: lightgrey;
  height: 29px;
  z-index: 2;

  &__button {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 4px 0 4px 4px;
    transition: all 0.2s ease;

    &:hover {
      background-color: white;
    }

    &.-back {
      background-image: url("../../assets/sprites/back_arrow.svg");
    }

    &.-forward {
      background-image: url("../../assets/sprites/forward_arrow.svg");
    }

    &.-refresh {
      background-image: url("../../assets/sprites/refresh_arrow.svg");
    }
  }

  &__search {
    display: inline-block;
    height: 16px;
    width: calc(100% - 84px);
    background-color: white;
    border: 2px solid black;
    margin: 4px;
    overflow: hidden;
  }

  &__url {
    position: relative;
    font-size: 12px;
    top: 2px;
    left: 4px;
    white-space: nowrap;
    overflow: hidden;
    cursor: text;
    outline: none;
  }
}
