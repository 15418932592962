@import "../../assets/font/font.scss";

.icon {
  position: relative;
  display: flex;
  margin: 20px;
  margin-bottom: 35px;
  border-radius: 10px;
  justify-content: center;
  cursor: url("../../assets/sprites/folder-icon.svg"), auto;	
  user-select: none;

  &__thumbnail {
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 75px;

    &.-text {
      background-image: url("../../assets/sprites/text-icon.svg");
    }

    &.-folder {
      background-image: url("../../assets/sprites/folder-icon.svg");
    }

    &.-explorer {
      background-image: url("../../assets/sprites/explorer-icon.svg");
    }

    &.-recycle {
      background-image: url("../../assets/sprites/recycle-icon.svg");
    }

    &.-computer {
      background-image: url("../../assets/sprites/computer-icon.svg");
    }

    &.-spotify {
      background-image: url("../../assets/sprites/spotify-icon.svg");
    }

    .custom, .multi {
      display: inline-block;
      max-width: 90%;
      max-height: 90%;
      border: 2px solid blue;
    }

    .multi {
      position: absolute;
      max-width: 70%;
      max-height: 70%;

      &.-p1 {
        bottom: 0px;
        left: 0px;
      }

      &.-p2 {
        top: 7px;
        right: 7px;
      }

      &.-p3 {
        top: 0px;
        right: 0px;
      }
    }
  }

  &__title {
    position: absolute;
    font-family: "Inconsolata";
    font-size: 14px;
    text-align: center;
    top: 55px;
    width: 80px;
    overflow-wrap: break-word;

    &.-desktop {
      color: black;
    }
  }
}

.iconWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 0px;
  height: 0px;
}