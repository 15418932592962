@import "../../assets/font/font.scss";

.mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: blue;
  color: white;
  font-family: "Inconsolata";
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 0;
    height: 0;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    transition: all 0.5s ease-out;
  }

  &__header {
    padding: calc(60%) 20px 0 20px;
    font-size: 30px;
  }

  &__me {
    padding: 20px;

    a {
      color: white;
    }
  }

  &__footer {
    font-style: italic;
    font-size: 14px;
    padding: 0px 40px 100px 40px;
    text-align: center;
    margin-bottom: calc(60%);
  }
}