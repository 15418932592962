@import "../../assets/font/font.scss";

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: blue;
  z-index: 5;
  transition: all 1s ease;

  &__message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30px);
    font-family: "Inconsolata";
    color: white;
  }

  &__barContainer {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40%;
    height: 10px;
    border: 1px solid white;
  }

  &__bar {
    position: absolute;
    height: 100%;
    background-color: white;
    transition: all 1s ease;
  }
}