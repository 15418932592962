.pictureContainer {
  width: 100%;
  height: 100%;
  background-color: black;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75%;
    min-height: 80px;
  }

  &__picture {
    max-width: 100%;
    max-height: 100%;
  }

  &__arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    opacity: 0.5;
    transition: all 0.2s ease;
    background-color: black;

    &:hover {
      opacity: 0.8;
    }

    &.-left {
      left: 10px;
      background-image: url("../../assets/sprites/pic-left.svg");
    }

    &.-right {
      right: 10px;
      background-image: url("../../assets/sprites/pic-right.svg");
    }
  }

  &__row {
    display: inline-flex;
    align-items: center;
    height: 24%;
    min-height: 36px;

    &.-right {
      position: absolute;
      justify-content: flex-start;
      width: calc(50% - 40px);
      right: 0px;
    }

    &.-middle {
      position: absolute;
      justify-content: center;
      left: 50%;
      transform: translate(-50%,0);
    }

    &.-left {
      position: absolute;
      justify-content: flex-end;
      width: calc(50% - 40px);
    }

    &__pic {
      margin: 6px;

      &.-l3, &.-r3 {
        max-height: 40%;
        max-width: 40%;
        opacity: 0.2;
      }

      &.-l2, &.-r2 {
        max-height: 55%;
        max-width: 55%;
        opacity: 0.5;
      }

      &.-l1, &.-r1 {
        max-height: 70%;
        max-width: 70%;
        opacity: 0.8;
      }

      &.-m {
        max-height: 80%;
        max-width: 80%;
        border: 1px solid white;
      }
    }
  }
}
