.landing {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgb(235, 235, 235);
    background-size: cover;
  }

  &__windowSpawn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  &__desktop {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 60px);
  }

  &__recycle {
    position: absolute;
    right: 20px;
    bottom: 40px;
  }
}